<template>

<app-page theme="purple" :close-button="($route.params.product) ? $route.params.return : 'Recommendation'" :title="product.name" :label="type.short" :size-content="true">

	<app-title text="Seven steps" :is-uppercase="true" />

	<div class="steps-image" :style="{backgroundImage: 'url(' + product.steps[step - 1].image + ')'}">

		<div class="steps-image-next" v-if="step < 7" v-on:click="onNextClick"></div>
		<div class="steps-image-previous" v-if="step > 1" v-on:click="onPreviousClick"></div>

	</div>

	<div class="steps-title">Step {{ step }}</div>

	<div class="steps-text" v-html="product.steps[step - 1].text" />

	<div class="steps-pips">
		<div class="steps-pips-item" v-for="pip in 7" :key="pip" :class="{'is-active': pip === step}"></div>
	</div>

	<app-button :text="($route.params.product) ? 'Close' : 'Complete'" theme="purple" v-on:click="onCompleteClick" v-if="step === 7" />

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			step: 1
		}

	},

	computed: {

		product: function() {

			return (this.$route.params.product) ? this.$route.params.product : this.$store.getters['selection']

		},

		type: function() {

			return (this.$route.params.type) ? this.$route.params.type : this.$store.getters['selection/type']

		}

	},

	methods: {

		onPreviousClick: function() {

			this.step--;

		},

		onNextClick: function() {

			this.step++

		},

		onCompleteClick: function() {

			if (this.$route.params.product) {

				this.$router.push({
					name: this.$route.params.return
				})

			} else {

				this.$store.commit('completed', 'steps')

				this.$router.push({
					name: 'Recommendation'
				})

			}

		}

	}

}

</script>

<style scoped>

.steps-title {
	color: #fff;
	font-size: 36px;
	letter-spacing: 3.6px;
	text-transform: uppercase;
	margin-bottom: 20px;
	text-align: center;
}

.is-mobile .steps-title {
	font-size: 24px;
}

.steps-image {
	width: 100%;
	height: 400px;
	margin-bottom: 60px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border-left: 84px solid transparent;
	border-right: 84px solid transparent;
}

.is-mobile .steps-image {
	height: 140px;
	width: calc(100% - 100px);
	margin: 0px 50px 20px 50px;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
}

.steps-image-previous,
.steps-image-next {
	width: 64px;
	height: 64px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
	position: absolute;
	border: 3px solid #34D6F5;
	top: 50%;
	border-radius: 32px;
	margin-top: -32px;
}

.is-mobile .steps-image-previous,
.is-mobile .steps-image-next {
	width: 40px;
	height: 40px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 16px 16px;
	cursor: pointer;
	position: absolute;
	border: 2px solid #34D6F5;
	top: 50%;
	border-radius: 20px;
	margin-top: -20px;
}

.steps-image-previous {
	background-image: url(~@/assets/chevron.left.svg);
	left: -84px;
}

.is-mobile .steps-image-previous {
	left: -50px;
}

.steps-image-next {
	background-image: url(~@/assets/chevron.right.svg);
	right: -84px;
}

.is-mobile .steps-image-next {
	right: -50px;
}

.steps-text {
	color: #fff;
	font-size: 24px;
	line-height: 29px;
	margin-bottom: 60px;
	text-align: center;
}

.is-mobile .steps-text {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
}

.steps-pips {
	display: flex;
	justify-content: center;
	margin-bottom: 60px;
}

.is-mobile .steps-pips {
	margin-bottom: 20px;
}

.steps-pips-item {
	width: 10px;
	height: 10px;
	border: 2px solid #2A273E;
	background-color: #fff;
	transition: all 100ms linear;
	margin: 0px 3px;
	border-radius: 50%;
}

.steps-pips-item.is-active {
	border-color: #34D6F5;
	background-color: #2A273E;
}

</style>
